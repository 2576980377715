<template>
  <div>
    <Info v-if="selectedItem" :item="selectedItem" :isVisible="isInfoModalVisible"
          @update:isVisible="isInfoModalVisible = $event"/>

    <mast-head></mast-head>
    <!-- Domestic abuse -->
    <b-container class="modules color0  ">
      <div class="mt-4 mb-5 intro-text text-left ">
        <div>We have created a suite of modules dedicated to different aspects of this issue.</div>
        <div>Once you have completed the first three modules and The Foundation Quiz, the other modules will unlock.
        </div>
        <div>Each module will take between approximately 15-30 minutes to complete.</div>
        <div v-show="firstModulesCompleteStatus" class="font-weight-bold">We would love to <a target="_blank"
                                                                                              href="https://forms.gle/Af4kyfDTCujVX4wb7">hear
          your feedback</a> - you can do this anytime and it will only take a couple of minutes.
        </div>

      </div>
      <div class="module-buttons mb-5">
        <b-row class="my-4">
          <b-col class="text-left font-weight-bold">
            <div class="title">Level 1 - Domestic Abuse Awareness Foundation</div>
            <div>The first three modules cover the basic knowledge everyone should have and are recommended for all.</div>
          </b-col>
        </b-row>
        <div class="row d-flex align-items-stretch">
          <div
              :class="{completed: isInDB(item.id) }"
              class="col-md-4  mb-4 mb-md-2 d-flex flex-column"
              v-for="(item, index) in moduleDomesticAbuse.slice(0,3)"
              :key="item.id"
          >
            <button
                @click.stop="gotoCourse(item.dir)"
                class="w-100 background-transparent border-0  flex-grow-1">
            <span class="content-box-grid__block">
              <span class="content-box-grid__block-image"
                    :style="{ backgroundImage: `url(${getImageUrl(item.id)})` }"></span>
              <span class="content-box-grid__block-content label text-left ">
                <span class="label-title">{{ item.title }}
                </span>
                <span class="label-intro mt-2">
                  {{ item.intro }}
                </span>
              </span>
            </span>
            </button>
            <button
                @click="openInfoModal(item)"
                class="w-100 border-0 font-weight-bold background-color3 info mt-2 py-2">Info
            </button>
          </div>
        </div>
        <div v-if="areInitialModulesComplete">
          <b-row class="my-4">
            <b-col md="3"></b-col>
            <b-col md="6">
              <button
                  @click.stop="gotoCourse(moduleDomesticAbuse[3].dir)"
                  class="btn-quiz w-100 background-color3  rounded-pill py-2 font-weight-bold"
                  v-html="moduleDomesticAbuse[3].title">
              </button>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
          <b-row class="my-4">
            <b-col class="text-left font-weight-bold">
              <div class="title">Level 2 - Children and Young People</div>
              <div>These three modules focus on developing your knowledge around how domestic abuse affects children and young people.</div>
            </b-col>
          </b-row>

          <div class="row d-flex align-items-stretch">
            <div
                :class="{completed: isInDB(item.id) }"
                class="col-md-4  mb-4 mb-md-2    d-flex flex-column"
                v-for="(item, index) in moduleDomesticAbuse.slice(4,7)"
                :key="item.id"
            >
              <button
                  @click.stop="gotoCourse(item.dir)"
                  class="w-100 background-transparent border-0  flex-grow-1">
            <span class="content-box-grid__block">
              <span class="content-box-grid__block-image"
                    :style="{ backgroundImage: `url(${getImageUrl(item.id)})` }"></span>
              <span class="content-box-grid__block-content label text-left ">
                <span class="label-title">{{ item.title }}
                </span>
                <span class="label-intro mt-2">
                  {{ item.intro }}
                </span>
              </span>
            </span>
              </button>
              <button
                  @click="openInfoModal(item)"
                  class="w-100 border-0 font-weight-bold background-color3 info mt-2 py-2">Info
              </button>
            </div>
          </div>
          <b-row class="my-4">
            <b-col md="3"></b-col>
            <b-col md="6">
              <button class="btn-quiz w-100 background-color3  rounded-pill py-2 font-weight-bold"
                      @click.stop="gotoCourse(moduleDomesticAbuse[12].dir)" v-html="moduleDomesticAbuse[12].title">
              </button>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
          <b-row class="my-4">
            <b-col class="text-left font-weight-bold">
              <div class="title">Other Level 2 Modules</div>
              <div>These modules support further professional development around domestic abuse.</div>
            </b-col>
          </b-row>
          <div class="row d-flex align-items-stretch mt-4">
            <div
                :class="{completed: isInDB(item.id) }"
                class="custom-col-5  d-flex flex-column"
                v-for="(item, index) in moduleDomesticAbuse.slice(7,12)"
                :key="item.id"
            >
              <button
                  @click.stop="gotoCourse(item.dir)"
                  class="w-100 background-transparent border-0 flex-grow-1">
            <span class="content-box-grid__block">
              <span class="content-box-grid__block-image"
                    :style="{ backgroundImage: `url(${getImageUrl(item.id)})` }"></span>
              <span class="content-box-grid__block-content label text-left ">
                <span class="label-title">{{ item.title }}
                </span>
                <span class="label-intro mt-2">
                  {{ item.intro }}
                </span>
              </span>
            </span>
              </button>
              <button
                  @click="openInfoModal(item)"
                  class="w-100 border-0 font-weight-bold background-color3 info mt-2 py-2">Info
              </button>
            </div>
          </div>
        </div>

      </div>

    </b-container>

  </div>

</template>

<script>
import firebase from "firebase";
import {db} from '../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";

import MastHead from "@/components/MastHead";
import Info from '../views/Info';

export default {
  components: {
    MastHead,
    Info

  },
  data() {
    return {
      firstModulesCompleteStatus: false,
      userData: null,
      module: languageObject['en'].module,
      modulesDBData: [],
      testDBData: null,
      name: '',
      selectedItem: null,
      isInfoModalVisible: false
    };
  },
  computed: {
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_FB_USER_MODULE_DATA',
      'GET_LOGGED_IN_STATUS'
    ]),
    ...mapState([
      'FIREBASE_UID',
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    moduleDomesticAbuse() {
      let modules = [];
      if (languageObject['en'].module) {
        modules = languageObject['en'].module;
      }
      return modules.filter(module => module.type === "domestic-abuse");
    },
    moduleModernSlavery() {
      let modules = [];
      if (languageObject['en'].module) {
        modules = languageObject['en'].module;
      }
      return modules.filter(module => module.type === "modern-slavery");
    },
    areInitialModulesComplete() {

      const jsonData = this.modulesDBData;

      //Ignore modern slavery
      const filteredModuleData = jsonData.filter(item => item.id !== "modern-slavery");
      const hasSufficientModules = filteredModuleData.length >= 3; //Unlock all modules after 1st 3 visited
      return hasSufficientModules;
    }
  },

  created() {
    if (this.GET_LOGGED_IN_STATUS) {
      this.getModuleData();
    }


  },
  watch: {
    GET_LOGGED_IN_STATUS() {
      this.getModuleData();
    },
    GET_FB_USER_MODULE_DATA() {
      const dataObj = this.GET_FB_USER_MODULE_DATA

      this.modulesDBData = dataObj.filter(obj => !obj.id.startsWith("module"));
    }
  },
  mounted() {

  },
  methods: {
    ...mapActions([
      'ACTION_FB_ADD_USER_MODULE_DATA',
      'ACTION_FB_GET_USER_MODULE_DATA'
    ]),
    getImageUrl(id) {
      return require(`@/assets/img/module-menu/${id}.jpg`);
    },
    openInfoModal(item) {
      this.selectedItem = item; // Set the selected item
      this.isInfoModalVisible = true; // Show the Info modal
    },
    doesIdExist(idToCheck) {


      // Iterate through each object in the array
      for (let i = 0; i < this.module.length; i++) {
        // Check if the current object's 'id' matches the idToCheck
        if (this.module[i].id === idToCheck) {
          return {
            "id": this.module[i].id,
            "title": this.module[i].title,
          }; // Return true if found
        }
      }
      return false; // Return false if not found
    },
    /*
    showThisModule(moduleIndex) {
      let bShowModule = false;
      /!*
      if (moduleIndex === (this.module.length - 1)) {//If last module?
        // Then this is the quiz
        if (this.areAllModulesComplete) {//All the modules complete
          //Then Show quiz
          bShowModule = true;
        }
      } else {// Else this isn't the quiz
        if (moduleIndex < 3) {//Is this the first 3 modules
          //Then show
          bShowModule = true;
        } else {//Not in first 3 modules
          // Are 1st 3 complete?
          if (this.isInDB("module1") && this.isInDB("module2") && this.isInDB("module3")) {
            //Then Show
            bShowModule = true;
            this.firstModulesCompleteStatus = true;
          }
        }
      }*!/

      return bShowModule;
    },*/
    getBGNum(index, item) {

      let bgNum = 2;
      if (index % 2 === 0) { //is Even
        bgNum = 1;
      }
      //if (this.isInDB(item.id)) {
      // bgNum = 0;
      // }
      return bgNum;
    },
    isInDB(moduleId) {
      let resultArray = [];
      if (this.modulesDBData) {
        resultArray = this.modulesDBData.filter(function (item) {
          return item["id"] === moduleId;
        });
      }
      return resultArray.length !== 0;
    },
    async getModuleData() {

      try {
        await this.ACTION_FB_GET_USER_MODULE_DATA();
      } catch (error) {
        console.error("Error getting module data: ", error);
      } finally {

        if (this.GET_FB_USER_MODULE_DATA) {
          this.modulesDBData = this.GET_FB_USER_MODULE_DATA.filter(obj => !obj.id.startsWith("module"));
        }

        if (this.$route.params.id) {
          //let moduleInt = 0;
          //moduleInt = parseInt(this.$route.params.id);
          //this.visitModule("module" + moduleInt)


          //console.log("this.module", this.module)

          console.log("this.doesIdExist(this.$route.params.id)", this.doesIdExist(this.$route.params.id))
          if (this.doesIdExist(this.$route.params.id)) {
            this.visitModule(this.doesIdExist(this.$route.params.id))
          } else {
            //console.warn(`Module with id '${this.$route.params.id}' does not exist.`);
            // Handle case where module ID doesn't exist
          }

        }
      }
    }
    ,
    visitModule(moduleObj) {
      //Add visit to the DB


      //if (!this.isInDB(moduleObj.id)) { //check if this module id is already in the DB. If it isn't then add it

        let data = {
          id: moduleObj.id,
          title: moduleObj.title,
          createdAt: firebase.firestore.Timestamp.now()
        };



        this.ACTION_FB_ADD_USER_MODULE_DATA(data);

/*      } else {
        //console.log(moduleId + " Is already in the DB so don't add!");
      }*/


    },
    gotoCourse(dir) {
      //Redirect to the actual course
      window.location.href = '/practitioners/' + dir + '/index.html';
    }
  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.title {
  font-size: 1.2rem;
  line-height: 1.3rem;
  font-weight: bold;
}


.intro-text {
  font-size: calc(clamp(1rem, 1.2vw, 1.4rem));
  line-height: calc(clamp(1.2rem, 1.4vw, 1.6rem));
  font-weight: 500;

  div {
    margin-bottom: .8rem;

    a {
      text-decoration: underline;
    }
  }
}

.active {
  color: white;
  background-color: $color3;
}


.btn-quiz {
  transition: background-color .6s ease-in-out, box-shadow .6s ease-in-out;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 2px 2px 8px;

  border: 2px solid $color1;

  &:hover {
    background-color: lighten($color3, 5) !important;
    box-shadow: 0 0 0 lighten($color0, 80);
  }
}


//Grid
.mb-4, .custom-col-5 {
  padding-left: 5px;
  padding-right: 5px;

}


</style>
