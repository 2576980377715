<template>

  <div class="background-color2 ">
    <b-container>
      <nav class=" nav  py-3 ">
        <div class="h-100 d-flex mb-md-2">
          <img class="h-100 w-auto " :src="require('@/assets/img/logo.svg'  )" alt="Logo"/>
        </div>
      </nav>
    </b-container>
    <div class="border-nav py-2">

    </div>

  </div>


</template>

<script>

import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';
import Help from "@/views/Help.vue";

export default {
  name: "top-header-public",
  components: {
    Help

  },
  data() {
    return {

    };
  },
  created() {

  },
  mounted() {

  },
  watch: {},

  computed: {
    ...mapState([

    ]),
    ...mapGetters([

    ])
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([

    ]),
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.border-nav {
  background-color: darken($color2, 2.5);
  box-shadow: 0 6px 2px -7px #ffffff;
  margin-bottom: 1px;

}

.nav {
  height: 80px;
  font-size: 1.3em;
  font-weight: 600;
}

nav a.router-link-exact-active {
  color: $color3 !important;
}

div {
  color: inherit;
}

a, a:link, a:hover, a:visited, a:active {
  margin: 5px;
}
</style>