<template>
  <div class="home">
    <mast-head></mast-head>
    <b-container class="my-5 text">
      <b-row>
        <b-col>
          <div class="text-center">
            <h2 class="color2 mb-5">Accessibility statement</h2>
          </div>
          <div class="text-left">
            <h3>Scope</h3>
            <p>This accessibility statement applies to:</p>
            <ul>
              <li><a  target="_blank" href="https://training.somersetdomesticabuse.org.uk/">Domestic Abuse and Modern Slavery: How to help in
                your community</a> (public site)
              </li>
              <li><a  target="_blank" href="https://practitioners.somersetdomesticabuse.org.uk/">Domestic abuse awareness</a> (agency site)
              </li>
            </ul>
            <p>This website is run by Somerset Survivors . We want as many people as possible to be able to use this
              website. For example, that means you should be able to:</p>
            <ul>
              <li>change colours, contrast levels and fonts using your browser controls</li>
              <li>zoom in up to 300% without the text spilling off the screen</li>
              <li>navigate the website using just a keyboard</li>
              <li>listen to the website using a screen reader (Chromevox, NVDA and Voiceover).</li>
              <li>navigate most of the website using speech recognition software.</li>
            </ul>
            <p>We&rsquo;ve also made the website text as simple as possible to understand.</p>
            <h3>How accessible this website is</h3>
            <p>We have worked hard to ensure the accessibility of this website but there are a few parts that do meet
              the guidelines but are worth noting:</p>
            <ul>
              <li>Interactions such as slideshows can be difficult to use when magnified to 400%, because the size of
                the graphic also increases and there are lots of elements to navigate and control, you would need a
                large screen for this level of magnification on some interactions.
              </li>
              <li>Some buttons are disabled until an action is completed &ndash; for example, the &lsquo;Submit&rsquo;
                button for an interaction, while they are disabled they will not change appearance when the focus is on
                them. Once they are active, they do change appearance when selected.
              </li>
            </ul>
            <h3>Feedback and contact information</h3>
            <p>If you need information on this website in a different format like accessible PDF, large print, easy
              read, audio recording or braille please email:&nbsp;<a  href="mailto:somersetdomesticabuse@desq.co.uk">somersetdomesticabuse@desq.co.uk</a>
            </p>
            <h3>Reporting accessibility problems with this website</h3>
            <p>We&rsquo;re always looking to improve the accessibility of this website. If you find any problems not
              listed on this page or think we&rsquo;re not meeting accessibility requirements, contact: <a
                  href="mailto:somersetdomesticabuse@desq.co.uk">somersetdomesticabuse@desq.co.uk</a></p>
            <h3>Enforcement procedure</h3>
            <p>The Equality and Human Rights Commission (EHRC) is responsible for enforcing the Public Sector Bodies
              (Websites and Mobile Applications) (No. 2) Accessibility Regulations 2018 (the &lsquo;accessibility
              regulations&rsquo;). If you&rsquo;re not happy with how we respond to your complaint, contact the Equality
              Advisory and Support Service (EASS).</p>
            <p>If you are in Northern Ireland and are not happy with how we respond to your complaint you can contact
              the&nbsp;<a  target="_blank" href="https://www.equalityni.org/Home">Equalities Commission for Northern Ireland</a>&nbsp;who
              are responsible for enforcing the Public Sector Bodies (Websites and Mobile Applications) (No. 2)
              Accessibility Regulations 2018 (the &lsquo;accessibility regulations&rsquo;) in Northern&nbsp;Ireland.&nbsp;
            </p>
            <h3>Technical information about this website&rsquo;s accessibility</h3>
            <p>This website is partially compliant with the&nbsp;<a  target="_blank" href="https://www.w3.org/TR/WCAG21/">Web Content
              Accessibility Guidelines version 2.1</a>&nbsp;- AA standard, due to the non-compliances listed below.</p>
            <h3>Non-accessible content</h3>
            <p>You cannot skip the side navigation to the main content when using a screen reader in the quiz &ndash;
              this is because there are only two buttons to skip, so implementing this would have only reduced
              navigation by one click and it risks making the user journey more complex for a screen reader user, rather
              than less complex (WCAG 2.1 success criterion 2.4.1)</p>
            <h3>Interactive tools and transactions</h3>
            <p>We have very few fields to complete and have not made them auto fill &ndash; the main one is the name you
              would like to put on your certificate. (WCAG 2.1 success criterion 1.3.5)</p>
            <p>There is a text entry field to enter the name you would to appear on your certificate when completing a
              quiz. We can&rsquo;t identify the purpose of fields programmatically as the authoring tool doesn&rsquo;t
              have this functionality, but they both contain placeholder text that is read by screen readers and
              indicates the purpose. (WCAG 2.1 success criterion 1.3.5)</p>
            <p>These two fields also do not auto-complete as that would not be desirable for the user. (WCAG 2.1 success
              criterion 1.3.5)</p>
            <h3>Disproportionate burden&nbsp;</h3>
            <p>We have not made any disproportionate burden claims.</p>
            <h3>Preparation of this accessibility statement</h3>
            <p>This statement was prepared on 3<sup>rd</sup>&nbsp;September 2021. It was last reviewed on 3<sup>rd</sup>&nbsp;September
              2021.</p>
            <h3>How we tested</h3>
            <p>Testing was carried out by manual testing applying WCAG 2.1 AA standards.</p>
            <p>The results of the testing were used to inform significant updates on the resources.</p>
          </div>
        </b-col>

      </b-row>


    </b-container>

  </div>
</template>

<script>
import Login from "@/views/Login";
import MastHead from "@/components/MastHead";
import {mapState, mapGetters} from "vuex";

export default {
  name: "home",
  components: {
    Login,
    MastHead
  },
  data() {
    return {};
  },
  computed: {
    ...mapState([
      'DEBUG_STATUS',
      'LOGGED_IN_STATUS'
    ]),
    ...mapGetters([
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {}
};
</script>
<style lang="scss">

.text {
  a {
    text-decoration: underline !important;
  }
}

.btn {
  border: none;
  width: 100%;
  min-width: 300px;
  font-size: 1.1em;
}

.btn-module {
  width: 500px;
}

@media only screen and (max-width: 768px) {
  .btn-module {
    width: 100%;
  }
}
</style>
