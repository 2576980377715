<template>
  <b-modal
      class="info"
      centered
      hide-footer
      hide-header
      id="info-modal"
      :visible="isVisible"
      @hide="hideModal"
      title="Info"
  >
    <div class="px-4 py-2 text-center">
      <h1 class="mb-4 mb-md-3 mt-1 color2 text-left"
          v-html="item.info.title"></h1>
      <div class="mt-3  text-left">
        <!-- Display passed data here -->
        <div v-html="item.info.body"></div>
      </div>

      <b-button class="mb-3 mt-3 color0 rounded-pill background-color1 w-auto mx-auto font-weight-bold" @click="hideModal">Close</b-button>
    </div>

  </b-modal>
</template>

<script>
import Info from '../views/Info';

export default {
  components: {
    Info
  },
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
    isVisible: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      user: {
        email: ''
      },
      errorMessage: ''
    };
  },
  methods: {

    hideModal() {
      this.$emit('update:isVisible', false);
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";

.modal-content {
  border-radius: 1.5rem !important;
}

::v-deep .modal-body {
  h1 {
    font-size: 1.8em !important;
  }



}


.btn:hover {
  background-color: #eee !important;
  border-color: #000000 !important;
}
.close-container {
  height: 15px;
}


.btn {
  border: none;
  width: 500px;
}


@media only screen and (max-width: 767px) {

  //Typography
  .modal-body {
    h1 {
      font-size: 1.3em !important;
    }
  }
  .btn {
    border: none;
    width: 100%;
  }

}

</style>
