<template>
  <div>
    <Info v-if="selectedItem" :item="selectedItem" :isVisible="isInfoModalVisible"
          @update:isVisible="isInfoModalVisible = $event"/>
    <mast-head></mast-head>
    <b-container>
      <div>
        <h2 class="color2 mb-5">Please choose your language:</h2>
      </div>

        <!--            <b-row>
                      <b-col class="mb-4" md="4">
                        <button
                            @click.stop="gotoCourse('en')"
                            class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1  background-transparent border-0 ">
                          <span class="label text-left  position-absolute top-0 left-0 py-3">Domestic Abuse and Modern Slavery</span>
                          <span
                              class="lang label text-left    position-absolute left-0 mb-4">English</span>
                        </button>
                        <div class="square"></div>
                      </b-col>
                      <b-col class="mb-4" md="4">
                        <button
                            @click.stop="gotoCourse('pt')"
                            class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1  background-transparent border-0 ">
                          <span
                              class="label text-left  position-absolute top-0 left-0 py-3">Violência doméstica e Escravatura moderna</span>
                          <span
                              class="lang label text-left    position-absolute left-0 mb-4">Português</span>
                        </button>
                        <div class="square"></div>
                      </b-col>
                      <b-col class="mb-4" md="4">
                        <button
                            @click.stop="gotoCourse('pl')"
                            class=" module-btn w-100 position-absolute top-0 left-0 h-100 z-index-1  background-transparent border-0 ">
                          <span
                              class="label text-left  position-absolute top-0 left-0 py-3">Przemoc domowa i współczesne niewolnictwo</span>
                          <span
                              class="lang label text-left    position-absolute left-0 mb-4">Polski</span>
                        </button>
                        <div class="square"></div>
                      </b-col>
                    </b-row>-->
        <b-row class="my-5">
          <b-col>
            <div class="module-buttons mb-5 ">
              <div class="row d-flex align-items-stretch">
                <div class="col-md-4  mb-4 mb-md-2   d-flex flex-column"
                     v-for="(item, index) in modulePublicHome"
                     :key="item.id">
                  <button
                      @click.stop="gotoCourse(item.dir)"
                      class="w-100 background-transparent border-0 flex-grow-1">
                        <span class="content-box-grid__block">
              <span class="content-box-grid__block-image"
                    :style="{ backgroundImage: `url(${getImageUrl(item.id)})` }"></span>
              <span class="content-box-grid__block-content label text-left ">
                <span class="label-title">{{ item.title }}
                </span>
                <span class="label-intro mt-2">
                  {{ item.intro }}
                </span>
              </span>
            </span>
                  </button>
                  <button
                      @click="openInfoModal(item)"
                      class="w-100 border-0 font-weight-bold background-color3 info mt-2 py-2">Info
                  </button>
                </div>
              </div>
            </div>
          </b-col>

        </b-row>
    </b-container>
  </div>


</template>

<script>
import MastHead from "@/components/public/MastHead";
import {languageObject} from "@/json/lang/lang";
import Info from "@/views/Info.vue";

export default {
  name: "public-index",
  components: {
    Info,
    MastHead
  },
  data() {
    return {
      selectedItem: null,
      isInfoModalVisible: false
    };
  },
  computed: {
    modulePublicHome() {
      let modules = [];
      if (languageObject['en'].module) {
        modules = languageObject['en'].module;
      }
      return modules.filter(module => module.type === "public-homepage");
    }

  },
  methods: {
    openInfoModal(item) {
      this.selectedItem = item; // Set the selected item
      this.isInfoModalVisible = true; // Show the Info modal
    },
    gotoCourse(lang) {
      //Redirect to the actual course
      window.location.href = './' + lang + '/';
    },
    getImageUrl(id) {
      return require(`@/assets/img/module-menu/${id}.jpg`);
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.intro-text {
  font-size: calc(clamp(1rem, 1vw, 2rem));

  div {
    margin-bottom: .8rem;

    a {
      text-decoration: underline;
    }
  }
}

/*
.module-buttons {
  @extend .col;
  padding-right: 7.5px;
  padding-left: 7.5px;
}


.completed {
  .square {
    background-color: $color2;
  }

  .module-btn {
    color: $color1;
  }
}


.square {
  border-radius: 2rem;
  padding-bottom: 82%;
  position: relative;
  height: 0;
  box-shadow: 0 3px 9px -8px #000000;
  background-color: $color1;

  transition: all .6s ease-in-out;

}

.module-btn:hover + .square {
  color: $color2;
  background-color: #EAEAEA;
}

.completed .module-btn:hover + .square {
  color: $color1 !important;
  background-color: darken($color2, 10) !important;
}

.module-btn {

  .lang {
    bottom: 15px;
    padding: 0;
  }

  color: $color2;

  .label {
    margin: 1rem 2rem;
    font-size: 1.3em;
    width: 200px;
    font-weight: 500;
  }
}


//Grid
.mb-4 {
  margin-bottom: 10px !important;
  padding-left: 5px;
  padding-right: 5px;

}*/


</style>