<template>
  <div class="background-color2">
    <b-container>
      <footer
          class="footer color1  footer-height     py-3 d-flex justify-content-center align-items-center">

        <div>
          View our <a target="_blank" href="https://www.somerset.gov.uk/our-information/privacy-notices-and-cookies/">privacy
          policy</a> and
          <router-link to="/accessibility">accessibility statement</router-link>
          .
        </div>
        <div class="ml-auto h-100  text-right d-flex justify-content-center align-items-center">
          <div><img class="py-3 footer-logo" :src="require('@/assets/img/logo.svg'  )" alt="Logo"/></div>
          <div>
          </div>
        </div>
        <CookieConsent></CookieConsent>
      </footer>
    </b-container>

  </div>
</template>
<script>
import Help from '../views/Help';
import CookieConsent from "@/components/CookieConsent";

export default {
  components: {
    Help,
    CookieConsent
  }
}
</script>
<style lang="scss" scoped>
a, a:link, a:hover, a:active, a:visited {
  text-decoration: underline;
}

.footer-logo {
  height: 80px !important;
}
</style>
