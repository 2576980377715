<template>

  <div class="background-color2 ">
    <b-container>
    <nav class=" nav  py-3 ">
      <div class="h-100 d-flex mb-md-2">
        <img class="h-100 w-auto " :src="require('@/assets/img/logo.svg'  )" alt="Logo"/>
      </div>
      <!-- Right aligned nav items -->
      <div class="ml-auto  py-md-2  h-100 d-flex text-right">
        <router-link class="color1" to="/">Home</router-link>
        <router-link class="color1 px-md-3" to="/register" v-if="!GET_LOGGED_IN_STATUS">Register</router-link>
        <router-link class="color1" to="/login" v-if="!GET_LOGGED_IN_STATUS">Login</router-link>
        <router-link class="color1 px-md-3" to="/modules" v-if="GET_LOGGED_IN_STATUS">Modules</router-link>
        <a href="#" class="color1" @click.stop="signOut" v-if="GET_LOGGED_IN_STATUS">Sign out</a>
        <a href="#" class="color1 pl-md-3" id="show-btn" @click.stop="$bvModal.show('help-modal')">Help</a>
      </div>
      <Help/>
    </nav>
    </b-container>
    <div class="border-nav py-2">

    </div>

  </div>


</template>

<script>
import firebase from 'firebase'
import "firebase/auth";
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

import Help from '../views/Help';

export default {
  name: "top-header",
  components: {
    Help
  },
  data() {
    return {
      loggedIn: this.FB_USER_DATA !== null,
      authDetails: {"admin": false}
    };
  },
  created() {
    this.setupFirebase();
    //this.getAuthData();
  },
  mounted() {

  },
  watch: {},

  computed: {
    ...mapState([
      'LOGGED_IN_STATUS',
      'DEBUG_STATUS',
      'FB_USER_MODULE_DATA'
    ]),
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN_STATUS']),
    ...mapActions([
      'ACTION_FB_SIGN_USER_OUT',
      'ACTION_FB_AUTO_SIGN_USER'
    ]),
    setupFirebase() {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //this.SET_LOGGED_IN_STATUS(true);
          //LOGGED_IN_STATUS
          this.loggedIn = true;
          this.ACTION_FB_AUTO_SIGN_USER(user);
        } else {
          // No user is signed in.
          this.loggedIn = false;
          //this.SET_LOGGED_IN_STATUS(false);
          //console.log("signed out", this.loggedIn);
        }
      });
    },
    signOut() {
      this.ACTION_FB_SIGN_USER_OUT();
      this.$router.replace({name: "login"})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.border-nav {
  background-color: darken($color2, 2.5);
  box-shadow: 0 6px 2px -7px #ffffff;
  margin-bottom: 1px;

}

.nav {
  height: 80px;
  font-size: 1.3em;
  font-weight: 600;
}

nav a.router-link-exact-active {
  color: $color3 !important;
}

div {
  color: inherit;
}

a, a:link, a:hover, a:visited, a:active {
  margin: 5px;
}
</style>
