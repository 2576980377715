<template>
  <div>
    <Nav/>
    <b-container class="text-left">
      <h1>Add new whitelist record:</h1>
      <form @submit.prevent="addToWhiteList" class="mb-3">
        <input aria-label="Email" v-model="email" class="form-control " name="email" id="email"
               autocomplete="email"
               placeholder="E.g. gov.uk, demo.gov.uk or example@demo.gov.uk"
               required="">
        <div class="my-3 alert alert-danger" v-show="bError"><strong>Error:</strong>
          <div v-html="error"></div>
        </div>
        <b-button class="background-color2 font-weight-bold" type="submit">Add TLD / Domain name / Email
        </b-button>
      </form>
      <div class="my-2">
        <h1>Current Whitelist:</h1>
      </div>
      <JsonCSV
          :name="csvDataFile"
          :labels="csvLabels"
          :fields="csvFields"
          :data="userTableDataItems">
        <b-btn>Download as CSV</b-btn>
      </JsonCSV>
      <b-table striped hover responsive align="center" id="user-report" :per-page="perPage"
               :fields="userTableDataFields"
               :items="userTableDataItems" :current-page="currentPage"></b-table>
      <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="user-report"
      ></b-pagination>

      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </b-container>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import {db} from '../../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";
import Nav from './Nav';

import Vue from 'vue';
import JsonCSV from 'vue-json-csv';
//import firebase from "../../../firebase.json";


export default {
  components: {
    JsonCSV,
    Nav
  },
  data() {
    return {
      bError: false,
      error: null,
      email: null,
      whiteList: null,
      name: 'whitelist-report',
      perPage: 50,
      currentPage: 1,
      userTableDataFields: [
        {
          key: 'whiteListed',
          label: 'TLD / Domain name / Email',
          sortable: true
        }
      ],
      userTableDataItems: [],
      csvDataFile: 'whitelist.csv',
      csvLabels: {
        whiteListed: 'TLD / Domain name / Email'
      },
      csvFields: ['whiteListed', 'moduleId', 'createdAt'],
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    module() {
      return languageObject['en'].module;
    },

    rows() {
      return this.userTableDataItems.length
    }
  },
  created() {
    this.getData();

  },
  watch: {},
  mounted() {
  },
  methods: {
    ...mapActions([]),
    addToWhiteList() {

      console.log("this.email.split(\".\")[0] ", this.email.split(".") );

      //Check type of record
      if (this.email.split(".")[0] === "") {
        // Illegal if preceded by a "."
        this.bError = true;
        this.error = "You cannot start with a '.' please use the format e.g: gov.uk";

      } else if (this.email.includes("@")) {
        //Email
        this.bError = false;
        db.collection("whitelist").doc("allowed")
            .update(
                {
                  emails: firebase.firestore.FieldValue.arrayUnion(this.email)
                }
            )
            .catch(function (error) {
              // Getting the Error details.
              console.log(error);
              this.bError = true;
              this.error = "<br/>" + error;

            });

      } else if (this.email.split(".").length > 2 && this.email.split(".")[this.email.split(".").length - 1] === "uk") {
        //Domain
        this.bError = false;
        this.error = "domain";
        db.collection("whitelist").doc("allowed")
            .update(
                {
                  domains: firebase.firestore.FieldValue.arrayUnion(this.email)
                }
            )
            .catch(function (error) {
              // Getting the Error details.
              console.log(error);
              this.bError = true;
              this.error = "<br/>" + error;

            });

      } else if (this.email.split(".").length <= 2 && this.email.split(".")[this.email.split(".").length - 1] === "uk") {
        //TLD
        this.bError = false;
        this.error = "TLD";
        db.collection("whitelist").doc("allowed")
            .update(
                {
                  tlds: firebase.firestore.FieldValue.arrayUnion(this.email)
                }
            )
            .catch(function (error) {
              // Getting the Error details.
              console.log(error);
              this.bError = true;
              this.error = "<br/>" + error;

            });
      } else if (this.email.split(".")[this.email.split(".").length - 1] !== "uk") {
        //Error not a uk domain
        this.bError = true;
        this.error = "Only .uk domain names are supported.<br/>Please contact the web site administrator to add manually.";
      } else {
        //All others - throw error
        this.bError = true;
        this.error = "<br/>Please contact the web site administrator to add manually.";
      }

      this.getData();
    },
    getData() {
      db.collection("whitelist").doc("allowed").get()
          .then((snapshot) => {
            // get the whitelist from FB

            const data = snapshot.data();
            const whiteListedTLDs = data?.tlds;
            const whiteListedDomains = data?.domains;
            const whiteListedEmails = data?.emails;
            // Concatenate the 3 arrays
            this.whiteList = whiteListedTLDs.concat(whiteListedDomains, whiteListedEmails);

            // console.log("whiteList", this.whiteList);
            this.parseWhiteListDBData();
          })
          .catch(error => {
            // Handle error
            console.log(error);
          });
    }
    ,
    parseWhiteListDBData() {
      this.userTableDataItems = [];
      this.whiteList.forEach(userData => {

        // console.log("userData", userData);

        this.userTableDataItems.push({
          whiteListed: userData
        });

      })

    }

  }
}
;
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  font-size: 1.8em !important;
}
</style>
