<template>
  <div>
    <Nav/>
    <div class="text-left mx-4">
      <div class="my-2">
        <h1>Modules Completed Report:</h1>
      </div>
      <JsonCSV
          :name="csvDataFile"
          :labels="csvLabels"
          :fields="csvFields"
          :data="moduleTableDataItems">
        <b-btn>Download as CSV</b-btn>
      </JsonCSV>
      <b-table striped hover responsive align="center" id="module-report" :per-page="perPage"
               :fields="userTableDataFields"
               :items="moduleTableDataItems" :current-page="currentPage"
               :sort-by.sync="sortBy.key"
               :sort-desc.sync="sortBy.order === 'desc'"
      ></b-table>
      <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="module-report"
      ></b-pagination>

      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { db } from '../../firebaseDatabase';
import { mapState, mapGetters, mapActions } from 'vuex';
import { languageObject } from "@/json/lang/lang";
import moment from 'moment';
import Nav from "@/views/admin/Nav";
import JsonCSV from 'vue-json-csv';

export default {
  components: {
    JsonCSV,
    Nav
  },
  data() {
    return {
      usersDBData: null,
      userDetailsDBData: null,
      name: 'admin-module-report',
      perPage: 20,
      currentPage: 1,

      userTableDataFields: [,
        {
          key: 'email',
          label: 'Email',
          sortable: true
        },
        {
          key: 'userForeName',
          label: 'Fore Name',
          sortable: true
        },
        {
          key: 'userLastName',
          label: 'Last Name',
          sortable: true
        },
        {
          key: 'selectedAgency',
          label: 'Selected Agency',
          sortable: true
        },
        {
          key: 'otherAgency',
          label: 'Other Agency',
          sortable: true
        },
        {
          key: 'moduleId',
          label: 'Module',
          sortable: true
        },
        {
          key: 'createdAt',
          label: 'Completed on',
          sortable: true,
          formatter: (value) => moment(value).format('DD-MM-YYYY')
        }
      ],
      moduleTableDataItems: [],
      userDetailsTableDataItems: [],
      csvDataFile: 'module-data.csv',
      csvLabels: {
        email: 'Email',
        userForeName: 'Fore Name',
        userLastName: 'Last Name',
        selectedAgency: 'Selected Agency',
        otherAgency: 'Other Agency',
        moduleId: 'Module',
        createdAt: 'Completed on'
      },
      csvFields: [
        'email',
        'userForeName',
        'userLastName',
        'selectedAgency',
        'otherAgency',
        'moduleId',
        'createdAt'
      ],
      sortBy: { key: 'createdAt', order: 'desc' }
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    module() {
      return languageObject['en'].module;
    },
    rows() {
      return this.moduleTableDataItems.length;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions([]),
    async fetchEmailData() {
      const userIds = this.userDetailsDBData.map(user => user.id);
      const getUserEmails = firebase.functions().httpsCallable('getUserEmails');
      try {
        const result = await getUserEmails({ userIds });
        const emailData = result.data;
        const filteredUserData = [];

        this.userDetailsDBData.forEach(userData => {
          const userId = userData.id;
          const email = emailData.find(user => user.uid === userId)?.email || '';

          // Filter out rows with empty email addresses
          if (email) {
            filteredUserData.push({
              userId: userId,
              email: email
            });
          }
        });

        return filteredUserData;
      } catch (error) {
        console.error("Error fetching emails: ", error);
        return false;
      }
    },
    async mergeUserDBDetailsAndModulesData(usersDBData, userDetailsDBData) {
      const emailData = await this.fetchEmailData();

      if (!emailData) {
        return;
      }

      const mergeUserData = [];
      usersDBData.forEach(module => {
        const matchedUserDBData = userDetailsDBData.find(user => user.id === module.id);
        if (matchedUserDBData) {
          module.data.modules.forEach((mdl, index) => {
            mergeUserData.push({
              userId: module.id,
              userForeName: matchedUserDBData.data.foreName,
              userLastName: matchedUserDBData.data.lastName,
              moduleId: module.data.modules[index].title,
              selectedAgency: matchedUserDBData.data.selectedAgency,
              otherAgency: matchedUserDBData.data.otherAgency,
              createdAt: module.data.modules[index].createdAt.toDate(),
              email: emailData.find(email => email.userId === module.id)?.email || ''
            });
          });
        }
      });

      this.moduleTableDataItems = mergeUserData;
    },
    async getData() {
      let usersDBData = [];
      let userDetailsDBData = [];

      await db.collection("data").get()
          .then(querySnapshot => {
            let queryData = querySnapshot.docs.map(doc => {
              return {id: doc.id, data: doc.data()};
            });

            // Filter out old modules data
            const filteredArray = queryData
                .map(item => {
                  return {
                    id: item.id,
                    data: {
                      modules: item.data.modules.filter(module => !module.id.startsWith("module"))
                    }
                  };
                })
                .filter(item => item.data.modules.length > 0);

            this.usersDBData = filteredArray;
            usersDBData = filteredArray;
          });

      await db.collection("userdetails").get()
          .then(querySnapshot => {
            let queryData = querySnapshot.docs.map(doc => {
              return {id: doc.id, data: doc.data()};
            });
            this.userDetailsDBData = queryData;
            userDetailsDBData = queryData;
          });

      await this.mergeUserDBDetailsAndModulesData(usersDBData, userDetailsDBData);
    },
    parseUserDetailsDBData() {
      this.userDetailsDBData.forEach(userData => {
        const userId = userData.id;
        this.userDetailsTableDataItems.push({
          userId: userId,
          selectedAgency: userData.data.selectedAgency,
          otherAgency: userData.data.otherAgency
        });
      });
    },
    parseUserDBData() {
      this.usersDBData.forEach(userData => {
        const userId = userData.id;
        const moduleDataArray = userData.data.modules;
        moduleDataArray.forEach((module) => {
          this.moduleTableDataItems.push({
            userId: userId,
            moduleId: module.id,
            createdAt: moment(module.createdAt.toDate()).format('DD-MM-YYYY')
          });
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  font-size: 1.8em !important;
}
</style>
