<template>
  <nav class="background-color2  nav px-4  py-3">
    <div class="h-100 d-flex align-items-center">
      <router-link class="color1" to="./user-report">Users</router-link>
      <router-link class="color1" to="./module-report">Modules</router-link>
      <router-link class="color1" to="./whitelist">Whitelist</router-link>
    </div>
  </nav>
</template>

<script>
import firebase from 'firebase'
import "firebase/auth";
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

export default {
  name: "top-header",
  data() {
    return {
      loggedIn: this.FB_USER_DATA !== null,
      authDetails: {"admin": false}
    };
  },
  created() {
    this.setupFirebase();
    //this.getAuthData();
  },
  mounted() {

  },
  watch: {},

  computed: {
    ...mapState([
      'LOGGED_IN_STATUS',
      'DEBUG_STATUS',
      'FB_USER_MODULE_DATA'
    ]),
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_LOGGED_IN_STATUS'
    ])
  },
  methods: {
    ...mapMutations(['SET_LOGGED_IN_STATUS']),
    ...mapActions([
      'ACTION_FB_SIGN_USER_OUT',
      'ACTION_FB_AUTO_SIGN_USER'
    ]),
    setupFirebase() {
      firebase.auth().onAuthStateChanged(user => {
        if (user) {
          //this.SET_LOGGED_IN_STATUS(true);
          //LOGGED_IN_STATUS
          this.loggedIn = true;
          this.ACTION_FB_AUTO_SIGN_USER(user);
        } else {
          // No user is signed in.
          this.loggedIn = false;
          //this.SET_LOGGED_IN_STATUS(false);
          console.log("signed out", this.loggedIn);
        }
      });
    },
    signOut() {
      this.ACTION_FB_SIGN_USER_OUT();
      this.$router.replace({name: "login"})
    }
  }
};
</script>

<style lang="scss" scoped>

.nav {
  height: 60px;
  font-size: 1.3em;
  font-weight: 600;
}

div {
  color: inherit;
}

a, a:link, a:hover, a:visited, a:active {
  margin: 5px;
}
</style>
