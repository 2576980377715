<template>
  <b-modal class="reset-password" hide-footer hide-header id="forgotton-password-modal" title="Forgotten Password">
    <div class=" w-100 d-flex justify-content-end close-container">
      <b-button class="btn-reset" @click="$bvModal.hide('forgotton-password-modal')">&times;</b-button>
    </div>
    <form @submit.prevent="forgetPassword">
      <h1 class="mb-4 mb-md-3 mt-1">Reset your password</h1>
      <div class="mt-2 font-weight-bold">You can change your password for security reasons or reset it if you forget it.</div>
      <div class="mt-2">You'll need to enter your email address below and click 'Reset password' to receive an email with a link in it.</div>
      <div class="mt-2">Click on the link in the reset email when you receive it, and you'll be prompted to enter a new password. You can then use the new password to login again.
      </div>
      <div class="form-group required my-4">
                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/email.png'  )" alt="Email icon"/>
                  </span>
        <input placeholder="E.g. example@gmail.com" aria-label="Email" type="email" name="email-reset" id="email-reset"
               class="form-control form-control-lg d-inline-block"
               autocomplete="email"
               required="" v-model="user.email"/>
      </div>
      <div v-if="errorMessage" class="alert alert-danger py-2">{{ errorMessage }}</div>
      <b-button class="background-color2 mb-3" type="submit">Reset password</b-button>
    </form>
    <div><span class="font-weight-bold ">Please Note:</span> the reset email may take some time to reach you and could appear in your spam / junk folder.</div>

  </b-modaL>
</template>

<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {
        email: ''
      },
      errorMessage: ''
    };
  },
  methods: {
    forgetPassword() {
      firebase
          .auth()
          .sendPasswordResetEmail(this.user.email)
          .then(() => {
            alert('Check your registered email to reset the password!')
            this.user = {
              email: ''
            }
          }).catch((error) => {
        this.errorMessage = error.message;

      })
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/main.scss";
.modal-body {
  h1 {
    font-size: 1.8em !important;
  }
}

.form-group {
  display: flex;
}


.close-container {
  height: 15px;
}

input, select {
  width: 100% !important;
}


input[type='checkbox'] {
  width: auto;
}

.btn {
  border: none;
  width: 500px;
}

.alert {
  width: 500px;
}

.icon {
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}


.btn.btn-reset {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 3em;
  padding: 0;
  height: 60px;
  min-width: 60px !important;
  width: 60px !important;
  color: $color6;
  background: transparent !important;
  border: none;

  &:hover {
    color: $color7;
    background-color: $color1 !important;
  }
}

@media only screen and (max-width: 767px) {

  //Typography
  .modal-body {
    h1 {
      font-size: 1.3em !important;
    }
  }
  .btn {
    border: none;
    width: 100%;
  }
  input {
    width: 80%;
    max-width: 80% !important;
  }
  .form-container {
    width: 100%;
    max-width: 100%;
  }

  .alert {
    width: 100%;
  }

}

</style>
