<template>
  <div class=" text-left login">
    <mast-head></mast-head>
    <b-container class="text-left">
      <b-row>
        <b-col class="text-center">
          <h2 class="color2 mb-5">Log into this online learning experience!</h2>
        </b-col>
      </b-row>
      <b-row class="mb-5 ">
        <b-col cols="12" class="mx-md-auto form-container">
            <form @submit.prevent="onSignin" class="mb-3">
              <div class="form-group required ">

                  <span class="icon text-center ">
                    <img :src="require('@/assets/img/icon/email.png'  )" alt="Email icon"/>
                  </span>
                  <input aria-label="Email" class="form-control " name="email" id="email" v-model="email"
                         type="email"
                         placeholder="E.g. example@gmail.com"
                         autocomplete="email"
                         required=""/>

              </div>
              <div class="form-group required ">


                    <span class="icon text-center ">
                      <img class="icon" :src="require('@/assets/img/icon/padlock.png'  )" alt="Password icon"/>
                  </span>
                    <input aria-label="Password" placeholder="Password" class="form-control "
                           name="password" id="password" v-model="password" type="password"
                           autocomplete="current-password"
                           required="" />
              </div>
                  <b-row class="my-3">
                    <b-col cols="6">
                    </b-col>
                    <b-col cols="6" class="text-right">
                      <a href="#" class="font-weight-bold " id="show-btn"
                         @click.stop="$bvModal.show('forgotton-password-modal')">
                        Forgot password
                      </a>
                    </b-col>
                  </b-row>
              <div class="my-3 alert alert-danger" v-if="dBError ">{{ dBError }}</div>
              <div class="my-4 text-center">
                <b-button class="background-color2 w-100 p-3 font-weight-bold" type="submit">Sign in</b-button>
              </div>
            </form>
            <PasswordReset v-show="showPasswordReset" @close="togglePasswordReset()"></PasswordReset>


        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import PasswordReset from './PasswordReset';
import MastHead from "@/components/MastHead";

export default {
  data() {
    return {
      email: "",
      password: "",
      error: "",
      showPasswordReset: false
    };
  },
  components: {
    PasswordReset,
    MastHead
  },
  computed: {
    ...mapGetters([
      'GET_FB_USER_DATA',
      'GET_FB_ERROR',
      'GET_FB_LOADING_STATUS'
    ]),
    user() {
      return this.GET_FB_USER_DATA
    },
    dBError() {
      return this.GET_FB_ERROR
    },
    loading() {
      return this.GET_FB_LOADING_STATUS
    }
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push('/')
      }
    }
  },
  methods: {
    ...mapActions([
      'ACTION_FB_SIGN_USER_IN',
      'ACTION_SET_ERROR',
      'ACTION_CLEAR_ERROR'
    ]),

    togglePasswordReset() {
      this.showPasswordReset = !this.showPasswordReset
    },
    onSignin() {
      this.ACTION_FB_SIGN_USER_IN({email: this.email, password: this.password});
    },
    onDismissed() {
      this.ACTION_CLEAR_ERROR();
    }
  }
};
</script>

<style lang="scss" scoped>

input, select {
  width: 100% !important;
}

.form-group {
  display: flex;
}

.form-container {
  max-width: 500px;
  font-size: 1.1em;
}

input[type='checkbox'] {
  width: auto;
}

.btn {
  border: none;
  width: 500px;
}

.alert {
  width: 500px;
}

.role-other {
  margin-left: 45px;
}

.icon {
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

@media only screen and (max-width: 768px) {
  select {
    width: 80%;
    max-width: 80% !important;
  }
}

</style>
