<template>
  <div>
    <div class="banner text-left  d-flex flex-column justify-content-center background-color2 text-center text-md-left"
    >
      <b-container>

        <b-row class="my-4 h-100">
          <b-col md="7" class=" d-flex flex-column justify-content-center">
            <div class=" header">
              <h1 class="color1 pl-md-1">{{headerText}}</h1>
            </div>
          </b-col>
          <b-col md="5" class=" d-none d-md-flex flex-column justify-content-center">
            <img class="header-image " :src="require('@/assets/img/header.png'  )" alt=""/>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="mb-5 divider"
         :style="{backgroundImage: 'url(' + require('@/assets/img/divider.png') + ')'}">
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      headerText: '', // initial value
    };
  },
  methods: {
    updateHeaderText(routePath) {
      if (['/', '/register', '/login'].includes(routePath)) {
        return 'Domestic Abuse Awareness and Modern Slavery';
      } else if (routePath.startsWith('/modules')) {
        return 'Domestic Abuse Awareness';
      }
      return ''; // Default text or reset
    },
  },
  watch: {
    '$route.path': function(newPath) {
      this.headerText = this.updateHeaderText(newPath);
    },
  },
  created() {
    this.headerText = this.updateHeaderText(this.$route.path);
  },
};
</script>

<style lang="scss" scoped>

.header-image {
  width: 100%;
  height: auto;
  padding: 0 0 4rem 0;

}

.divider {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transform: translateY(-50%);
  height: 15vh;
}

h1 {
  font-size: 3em;
  font-weight: bolder;
  padding: 0 0 4rem 0;
}

h2 {
  font-size: 2em;
}

.btn {
  min-width: 300px;
}
</style>
