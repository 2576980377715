<template>
  <div>
    <Nav/>
    <div class="text-left mx-4">
      <b-row class="my-2">
        <b-col><h1 class="d-inline-block">User Report:</h1></b-col>
        <b-col>
          <h1 class="font-weight-bold text-right">Total number of users: {{ userTableDataItems.length }}</h1>
        </b-col>
      </b-row>

      <JsonCSV
          :name="csvDataFile"
          :labels="csvLabels"
          :fields="csvFields"
          :data="userTableDataItems"
      >
        <b-btn>Download as CSV</b-btn>
      </JsonCSV>
      <b-table
          striped
          hover
          responsive
          align="center"
          id="user-report"
          :per-page="perPage"
          :fields="userTableDataFields"
          :items="userTableDataItems"
          :current-page="currentPage"
          :sort-by.sync="sortBy.key"
          :sort-desc.sync="sortBy.order === 'desc'"
      ></b-table>
      <b-pagination
          align="center"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          aria-controls="user-report"
      ></b-pagination>

      <p class="mt-3">Current Page: {{ currentPage }}</p>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import {db} from '../../firebaseDatabase';
import {mapState, mapGetters, mapActions} from 'vuex';
import {languageObject} from "@/json/lang/lang";
import moment from 'moment';
import Nav from "@/views/admin/Nav";
import JsonCSV from 'vue-json-csv';

export default {
  components: {
    JsonCSV,
    Nav
  },
  data() {
    return {
      usersDBData: null,
      name: 'admin-user-report',
      perPage: 20,
      currentPage: 1,
      userTableDataFields: [
        {key: 'userForeName', label: 'Fore Name', sortable: true},
        {key: 'userLastName', label: 'Last Name', sortable: true},
        {key: 'email', label: 'Email', sortable: true},
        {key: 'selectedAgency', label: 'Agency', sortable: true},
        {key: 'selectedGPPractice', label: 'GP Practice', sortable: true},
        {key: 'otherAgency', label: 'Other Agency', sortable: true},
        {key: 'selectedKnowledge', label: 'Knowledge', sortable: true},
        {key: 'selectedConfidence', label: 'Confidence', sortable: true},
        {key: 'selectedRole', label: 'Role', sortable: true},
        {key: 'otherRole', label: 'Other Role', sortable: true},
        {key: 'selectedCounty', label: 'County', sortable: true},
        {key: 'otherCounty', label: 'Other County', sortable: true},
        {
          key: 'createdAt',
          label: 'Registered on',
          sortable: true,
          formatter: (value) => moment(value).format('DD-MM-YYYY')
        }
      ],
      userTableDataItems: [],
      csvDataFile: 'user-data.csv',
      csvLabels: {
        userForeName: 'Fore Name',
        userLastName: 'Last Name',
        email: 'Email',
        selectedAgency: 'Agency',
        selectedGPPractice: 'GP Practice',
        otherAgency: 'Other Agency',
        selectedKnowledge: 'Knowledge',
        selectedConfidence: 'Confidence',
        selectedRole: 'Role',
        otherRole: 'Other Role',
        selectedCounty: 'County',
        otherCounty: 'Other County',
        createdAt: 'Registered on'
      },
      csvFields: [
        'userForeName',
        'userLastName',
        'email',
        'selectedAgency',
        'selectedGPPractice',
        'otherAgency',
        'selectedKnowledge',
        'selectedConfidence',
        'selectedRole',
        'otherRole',
        'selectedCounty',
        'otherCounty',
        'createdAt'
      ],
      sortBy: {key: 'createdAt', order: 'desc'}
    };
  },
  computed: {
    ...mapGetters([]),
    ...mapState([]),
    module() {
      return languageObject['en'].module;
    },
    rows() {
      return this.userTableDataItems.length;
    }
  },
  created() {
    this.getData();
  },
  methods: {
    ...mapActions([]),
    getData() {
      db.collection("userdetails").get().then(querySnapshot => {
        let queryData = querySnapshot.docs.map(doc => {
          return {
            id: doc.id,
            data: doc.data()
          };
        });
        this.usersDBData = queryData;
        this.fetchEmailsAndParseUserDBData();
      });
    },
    fetchEmailsAndParseUserDBData() {
      const userIds = this.usersDBData.map(user => user.id);
      const getUserEmails = firebase.functions().httpsCallable('getUserEmails');
      getUserEmails({userIds}).then(result => {
        const emailData = result.data;
        const filteredUserData = [];

        this.usersDBData.forEach(userData => {
          const userId = userData.id;
          const email = emailData.find(user => user.uid === userId)?.email || '';

          // Filter out rows with empty email or email containing 'desq.co.uk'
          //if (email && !email.includes('desq.co.uk')) {
          if (email) {
            filteredUserData.push({
              userId: userId,
              userForeName: userData.data.foreName,
              userLastName: userData.data.lastName,
              email: email,
              selectedAgency: userData.data.selectedAgency,
              selectedGPPractice: userData.data.selectedGPPractice,
              otherAgency: userData.data.otherAgency,
              selectedRole: userData.data.selectedRole,
              otherRole: userData.data.otherRole,
              selectedKnowledge: userData.data.selectedKnowledge,
              selectedConfidence: userData.data.selectedConfidence,
              selectedCounty: userData.data.selectedCounty,
              otherCounty: userData.data.otherCounty,
              createdAt: userData.data.createdAt.toDate()
            });
          }
        });

        // Update the table data with filtered results
        this.userTableDataItems = filteredUserData;
      }).catch(error => {
        console.error("Error fetching emails: ", error);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

h1 {
  font-size: 1.8em !important;
}
</style>
